
import { Options, prop, Vue } from "vue-class-component";
import { downloadFile } from "@/app/infrastructures/misc/Utils";

class Props {
  name = prop<string>({
    default: "",
    type: String
  });
  url = prop<string>({
    default: "",
    type: String
  });
  maxLength = prop<number>({
    default: 50,
    type: Number
  });
  error = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorCaption = prop<string>({
    default: "",
    type: String
  });
  linked = prop<boolean>({
    default: false,
    type: Boolean
  });
  img = prop<boolean>({
    default: false,
    type: Boolean
  });
  bordered = prop<boolean>({
    default: true,
    type: Boolean
  });
  dash = prop<boolean>({
    default: true,
    type: Boolean
  });
}

@Options({
  name: "DownloadAttachment"
})
export default class DownloadAttachment extends Vue.with(Props) {
  shortNameFile(name: string, length: number) {
    const shortName = `${name.slice(0, length)}...`;
    return name.length > length ? shortName : name;
  }

  download(url: string, fileName: string) {
    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    const fileExt = fileName.split(".").pop();
    downloadFile(url, newFileName, `.${fileExt}`);
  }
}
